import project1  from './img/project1.png'
export const products = [
  {
    id: 1,
    img:project1 ,
    link: "https://github.com/sulubsson/Expenses-tracker",
  },
  {
    id: 2,
    img: "",
    link: "http://lama.dev",
  },
  {
    id: 3,
    img: "",
    link: "http://lama.dev",
  }
];
