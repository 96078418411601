import "./about.css";
import Examen from "../../img/examen.jpg";
import AboutImg from '../../img/About.jpg'

const About = () => {
  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img
            src={AboutImg}
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
        I'am a software developer with good amount of skills.
        Ahmed is driven by his passion for understanding algorithms in programming. He considers himself an "eternal student", eager to both build on his academic foundations in software development and stay in tune with the latest technologies through continuing courses. Contact ahmed@sulubsson.com to connect.
        </p>
        <p className="a-desc">
JAVA
HTML
CSS
Javascript (ES6)
Angular
React
React-Native
Nodejs
Express
Mongo DB
UX-Design
Scrum
Git
Github

        </p>
        <div className="a-award">
          <img src={Examen} alt="" className="a-award-img" />
          <div className="a-award-texts">
            <h4 className="a-award-title">frontend developer diploma at EC Utbildning AB Växjö 2021</h4>
            <p className="a-award-desc">
            I've studied frontend/fullstack development between year 2019 to 2021 and specialized in javascript.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
